import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  viewBox: "0 0 16 16"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      fill: "transparent",
      stroke: "currentColor",
      "stroke-linecap": "round",
      "stroke-linejoin": "round",
      "stroke-width": "1.5",
      d: "M4 6.665V5.332c0-2.207.667-4 4-4s4 1.793 4 4v1.333m-4 5.668A1.667 1.667 0 1 0 8 9a1.667 1.667 0 0 0 0 3.333"
    }, null, -1),
    _createElementVNode("path", {
      fill: "transparent",
      stroke: "currentColor",
      "stroke-linecap": "round",
      "stroke-linejoin": "round",
      "stroke-width": "1.5",
      d: "M11.334 14.668H4.667c-2.667 0-3.333-.667-3.333-3.333V10c0-2.666.666-3.333 3.333-3.333h6.667c2.666 0 3.333.667 3.333 3.333v1.334c0 2.666-.667 3.333-3.333 3.333Z"
    }, null, -1)
  ])))
}
export default { render: render }